import React, { useEffect, useRef, useState } from "react";
import styles from "../../produtoIndividual/produto.module.css";
import Header from "../../../components/espanhol/es_Header/es_header";
import Footer from "../../../components/espanhol/es_Footer/es_footer";
import { useParams } from "react-router-dom";
import produtos from "./dados.json";

function Produtos() {
  const { id } = useParams();
  const produto = produtos.find((p) => p.id === id);
  const imgRef = useRef(null);
  const zoomRef = useRef(null);
  const [zoomPos, setZoomPos] = useState({ x: 0, y: 0 });

  if (!produto) {
    window.location.href = "/produtos";
  }

  const dados = produto.dados.split("@");
  let imagem = require(`../../../assets/img/produtos/${produto.arq}`);

  useEffect(() => {
    document.title = `${produto.tit}`;

    const handleMouseMove = (e) => {
      const img = imgRef.current;
      const zoom = zoomRef.current;
      const rect = img.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const xPercent = (x / img.width) * 100;
      const yPercent = (y / img.height) * 100;

      setZoomPos({ x, y });
      zoom.style.left = `${x - zoom.offsetWidth / 2}px`;
      zoom.style.top = `${y - zoom.offsetHeight / 2}px`;
      zoom.style.backgroundPosition = `${xPercent}% ${yPercent}%`;
    };

    const img = imgRef.current;
    const updateZoomEffect = () => {
      if (window.innerWidth > 950) {
        img.addEventListener("mousemove", handleMouseMove);
      } else {
        img.removeEventListener("mousemove", handleMouseMove);
      }
    };

    updateZoomEffect();
    window.addEventListener("resize", updateZoomEffect);

    return () => {
      img.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", updateZoomEffect);
    };
  }, []);

  return (
    <main>
      <Header />
      <section className={styles.container_produto}>
        <div className={styles.img_container}>
          <img src={imagem} ref={imgRef} alt={produto.tit} className={styles.img_container_prod} />
          <div
            className={styles.zoom}
            ref={zoomRef}
            style={{ backgroundImage: `url(${imagem})` }}
          ></div>
        </div>
        <div className={styles.descricao_produto}>
          <h1>{produto.tit}</h1>
          <p>{produto.sub}</p>
          <ul>
            <li>{dados[0]}</li>
            <li>{dados[1]}</li>
            <li>{dados[2]}</li>
            <li>{dados[3]}</li>
            <li className={styles.li_caixa}>{dados[4]}</li>
            <li className={styles.li_caixa}>{dados[5]}</li>
            <li className={styles.li_caixa}>{dados[6]}</li>
            <li>{dados[7]}</li>
            <li>{dados[8]}</li>
            {dados[9] ? <li>{dados[9]}</li> : null}
          </ul>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default Produtos;
