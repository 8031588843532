import React from 'react';
import styles from "./en_popup.module.css";
import popup from "../../../assets/img/en_popup.png";

const Popup = ({ handleClose }) => {
  const handleOverlayClick = (e) => {
    // Fecha o popup ao clicar fora do conteúdo
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <div className={styles.popup_overlay} onClick={handleOverlayClick}>
      <div className={styles.popup_content}>
        <button onClick={handleClose} className={`bi bi-x-lg ${styles.close_button}`}></button>
        <img src={popup} alt="Popup" />
      </div>
    </div>
  );
};

export default Popup;
